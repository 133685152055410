import React from "react";
import {useDispatch} from "react-redux";
import {Helmet} from "react-helmet";
import {Navigate, useParams} from "react-router-dom";
import {Link as LinkScroll} from "react-scroll";

import {useTypedSelector} from "../hooks/useTypedSelector";

import {fetchCourseByUrl} from "../redux/actions/courses";
import {addUserCourse} from "../redux/actions/user";

import {
    CoursePageMain,
    CoursePageContent,
    CoursePageSkills,
    CoursePageUseSkills,
    CoursePageFeedbacks,
    CoursePagePrice,
    CoursePageComparisonCourses,
    CoursePagePassing,
    CoursePageTools,
    CoursePageMaster,
    CoursePageFaq,
    Loader,
} from "../components";

import {checkIsAddCourse} from "../functions/checkIsAddCourse";

const CoursePage: React.FC = () => {
    const {url} = useParams();

    const dispatch = useDispatch();

    const {courseByUrl, isLoadedCourseByUrl} = useTypedSelector(
        ({courses}) => courses
    );
    const categories = useTypedSelector(({categories}) => categories.items);
    const isLoadedCategories = useTypedSelector(
        ({categories}) => categories.isLoadedAllCategories
    );
    const masters = useTypedSelector(({masters}) => masters.items);
    const isLoadedMasters = useTypedSelector(({masters}) => masters.isLoaded);
    const {userInfo, isLoadedUserInfo} = useTypedSelector(({user}) => user);

    const [visibleButton, setVisibleButton] = React.useState(false);

    const [isLogin, setIsLogin] = React.useState(false);
    const [isAdd, setIsAdd] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("scroll", handlerScroll);
    }, []);

    React.useEffect(() => {
        return () => {
            window.removeEventListener("scroll", handlerScroll);
        };
    }, []);

    React.useEffect(() => {
        dispatch(fetchCourseByUrl("creating-an-nstrumental-fl-studio-dsprite"));
    }, [url]);

    React.useEffect(() => {
        if (
            localStorage.getItem("accessToken") &&
            isLoadedUserInfo &&
            isLoadedCourseByUrl
        ) {
            setIsLogin(true);

            setIsAdd(checkIsAddCourse(userInfo.courses, courseByUrl._id));
        }
    }, [url, isLoadedUserInfo, isLoadedCourseByUrl]);

    const handlerScroll = () => {
        if (Math.floor(window.pageYOffset) > 200) {
            setVisibleButton(true);
        } else {
            setVisibleButton(false);
        }
    };

    const onClickAddCourse = () => {
        dispatch(addUserCourse(courseByUrl._id));
    };

    return (
        <>
            <Helmet>
                <title>
                    Создание Инструментала в FL Studio от DSPRITE | HobJob -
                    HobJob
                </title>
            </Helmet>

            <LinkScroll
                to="price"
                spy={true}
                smooth={true}
                offset={0}
                duration={1000}
                className={`btn course-page__btn ${
                    visibleButton ? "visible" : ""
                }`}
            >
                Начать обучение
            </LinkScroll>

            <CoursePageMain />

            {isLoadedCourseByUrl && isLoadedMasters ? (
                <>
                    <CoursePageContent />
                    <CoursePageSkills />
                    <CoursePageUseSkills {...courseByUrl} />
                    <CoursePagePrice />
                    <CoursePagePassing />
                    <CoursePageTools {...courseByUrl} />
                    <CoursePageMaster master={masters[courseByUrl.masterId]} />
                    <CoursePageFaq />{" "}
                </>
            ) : null}
        </>
    );
};

export default CoursePage;
