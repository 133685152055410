import React from "react";

import {Master} from "../../../models/IMaster";

import {Instagram, Vk, TikTok, Telegram, CoursePageMasterWorks} from "../../";

interface CoursePageMasterProps {
    master: Master;
}

const CoursePageMaster: React.FC<CoursePageMasterProps> = ({master}) => {
    return (
        <>
            <section className="course-page-master">
                <div className="container">
                    <div className="course-page-master-wrapper">
                        {master.worksImage.length ||
                        master.worksVideo.length ? (
                            <CoursePageMasterWorks />
                        ) : null}
                    </div>
                </div>
            </section>
        </>
    );
};

export default CoursePageMaster;
