import React from "react";

import {CoursePageSkillsItem} from "../../";

const CoursePageSkills: React.FC = () => {
    const skills = [
        {
            title: "Как Работать с FL Studio",
            description: "Поймёте принцип работы программ для создания музыки",
        },
        {
            title: "На примере, увидите как сводить дорожки, что бы звук звучал дорого",
            description:
                "Увидите, как сводить дорожки, чтобы звук звучал дорого",
        },
        {
            title: "Писать инструментал с нуля",
            description:
                "Узнаете, как пишется музыка от мелодии до выстраивания структуры песни",
        },
    ];

    return (
        <section className="course-page-skills">
            <div className="container">
                <div className="course-page-skills-wrapper">
                    <h2 className="title__mb course-page-skills__title">
                        Вы поймёте:
                    </h2>

                    <div className="course-page-skills-items-wrapper">
                        {skills.map((skill, index) => (
                            <CoursePageSkillsItem
                                {...skill}
                                key={`course-page-skills-item-${index}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoursePageSkills;
