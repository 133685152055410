import React from "react";
import {Link as LinkScroll} from "react-scroll";

import {useTypedSelector} from "../../../hooks/useTypedSelector";

import {
    CoursePageContentVideo,
    CoursePageContentLessons,
    CoursePageContentMaterials,
} from "../../";

const CoursePageContent: React.FC = () => {
    const {
        courseByUrl: {_id, lessons},
    } = useTypedSelector(({courses}) => courses);

    return (
        <section className="course-page-content">
            <div className="container">
                <div className="course-page-content-wrapper">
                    <div className="course-page-content-video">
                        <CoursePageContentVideo
                            courseId={_id}
                            image={lessons[0].image}
                        />
                    </div>

                    <div className="course-page-content-list">
                        <CoursePageContentLessons lessons={lessons} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoursePageContent;
