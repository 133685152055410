import React from "react";

import {CoursePageUseSkillsItem} from "../../";

import {CourseGoodUseSkill} from "../../../models/Course/ICourseGood";

interface CoursePageUseSkillsProps {
    useSkills: CourseGoodUseSkill[];
}

const CoursePageUseSkills: React.FC<CoursePageUseSkillsProps> = ({
    useSkills,
}) => {
    return (
        <section className="course-page-use-skills">
            <div className="container">
                <div className="course-page-use-skills-wrapper">
                    <div className="course-page-use-skills-block-title">
                        <h2 className="title__mb course-page-use-skills-block-title__title">
                            По итогу курса вы сможете:
                        </h2>
                    </div>

                    <div className="course-page-use-skills-block-text">
                        <div className="course-page-use-skills-block-text-item-wrapper">
                            {useSkills.map((useSkill, index) => (
                                <CoursePageUseSkillsItem
                                    {...useSkill}
                                    key={`course-page-use-skills-block-text-item-${index}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoursePageUseSkills;
