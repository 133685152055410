import React from "react";

const CoursePagePassing: React.FC = () => {
    return (
        <section className="course-page-passing">
            <div className="container">
                <div className="course-page-passing-wrapper">
                    <h2 className="title__mb course-page-passing__title">
                        Как проходит обучение?
                    </h2>

                    <div className="course-page-passing-items">
                        <p className="course-page-passing__item">
                            1. Получаете доступ к курсу
                        </p>
                        <p className="course-page-passing__item">
                            2. Переходите в личный Кабинет
                        </p>
                        <p className="course-page-passing__item">
                            3. Проходите курс
                        </p>
                        <p className="course-page-passing__item color">
                            4. Новый навык
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoursePagePassing;
