import React from "react";
import {Link} from "react-router-dom";
import {Link as LinkScroll} from "react-scroll";

const CoursePageMain: React.FC = () => {
    return (
        <section className="course-page-main">
            <div className="container">
                <div className="course-page-main-wrapper">
                    <div className="course-page-main-text">
                        <span className="course-page-main-text__category">
                            Музыка
                        </span>

                        <span className="course-page-main-text__category">
                            HobJob Production
                        </span>

                        <h2 className="course-page-main-text__title">
                            Создание Инструментала в <span>FL Studio</span> от{" "}
                            <span className="color">DSPRITE</span>
                        </h2>

                        <p className="course-page-main-text__description">
                            На курсе вы познакомитесь с моей техникой написания
                            инструментала, узнаете мои фишки и видение создания
                            музыки, а также поймёте принцип работы программ для
                            написания музыки
                        </p>
                        <LinkScroll
                            to="price"
                            spy={true}
                            smooth={true}
                            offset={-25}
                            duration={1000}
                        >
                            <button className="btn course-page-main-text__btn">
                                Начать обучение
                            </button>
                        </LinkScroll>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoursePageMain;
