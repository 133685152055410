import React from "react";
import {Link, NavLink} from "react-router-dom";

import Logo from "../../assets/images/logo.svg";

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-wrapper">
                    <div className="footer-logos">
                        <Link to="/" className="footer-logos-logo__link">
                            <img
                                src={Logo}
                                alt="HobJob"
                                className="footer-logos-logo__img"
                            />
                        </Link>
                    </div>
                    <div className="footer-socials">
                        <a
                            href="https://www.instagram.com/hobjob.ru"
                            className="footer-socials__link"
                        >
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M38.4737 0.105732C31.575 0.398878 23.5623 1.00471 18.5202 1.57146C13.439 2.15775 9.9213 3.81891 6.6967 7.16077C5.016 8.91964 4.27336 10.014 3.27667 12.1052C2.29952 14.1963 1.83048 16.2483 1.45917 20.0396C-0.162905 36.4362 -0.436508 54.0836 0.657902 70.2652C1.30282 79.5677 1.75231 83.359 2.45586 85.5869C3.37438 88.5184 4.52742 90.4336 6.6967 92.7201C9.9213 96.062 13.4195 97.7232 18.5202 98.3095C21.7448 98.6808 29.4839 99.3061 34.7605 99.6188C40.3107 99.9511 59.502 99.9511 65.0522 99.6188C74.5306 99.0521 82.0742 98.3681 84.3021 97.8795C87.683 97.1173 90.419 95.5539 93.0964 92.8569C96.3015 89.6128 97.8063 86.3687 98.373 81.4438C100.542 62.448 100.542 37.4525 98.373 18.4566C97.9431 14.6848 96.9073 11.812 95.0507 9.19324C94.0736 7.82523 91.748 5.53869 90.3995 4.62017C88.9142 3.60393 86.3541 2.48998 84.5757 2.07958C82.211 1.51283 75.1559 0.86791 65.5408 0.301163C60.9091 0.0275602 43.3008 -0.109241 38.4737 0.105732ZM67.1824 19.2188C73.827 20.9777 78.9668 26.1175 80.7257 32.7621C81.1556 34.3842 81.1556 34.6383 81.2143 49.1196C81.2924 65.1254 81.2729 65.6921 80.198 68.7409C78.1656 74.5451 73.2407 79.04 67.1628 80.6621C65.5798 81.092 65.2672 81.092 50.8249 81.1507C34.8191 81.2288 34.2524 81.2093 31.2037 80.1344C25.3603 78.0824 20.8263 73.1185 19.2824 67.0211C18.8329 65.2817 18.8329 65.2231 18.8329 49.9405C18.8329 34.6578 18.8329 34.5992 19.2824 32.8598C21.1781 25.3749 27.3341 19.9028 35.0536 18.8084C35.3859 18.7693 42.3627 18.7302 50.5903 18.7498C65.3649 18.7889 65.5603 18.7889 67.1824 19.2188Z"
                                    fill="white"
                                />
                                <path
                                    d="M34.858 25.2381C30.1872 26.1566 26.1809 30.2215 25.2819 34.951C24.852 37.257 24.852 62.7411 25.2819 64.9495C26.005 68.6041 28.6042 72.0045 31.8484 73.5484C34.7017 74.8969 34.0763 74.8578 50.0039 74.8578C65.9314 74.8578 65.3061 74.8969 68.1593 73.5484C71.4426 71.985 73.9441 68.7018 74.7063 64.9495C75.1557 62.7216 75.1753 37.1984 74.7258 34.9314C73.7487 30.0652 69.801 26.1175 64.9934 25.2185C62.8241 24.8081 36.9491 24.8277 34.858 25.2381ZM67.9053 30.1825C68.3352 30.3779 68.9802 30.886 69.3124 31.2769C71.7553 34.2474 68.8824 38.6251 65.2083 37.5502C62.8241 36.8662 61.6515 34.2279 62.7264 32.0195C63.6449 30.1238 65.951 29.303 67.9053 30.1825ZM54.0297 34.306C59.834 35.7913 64.3289 40.4035 65.7556 46.3641C66.166 47.9862 66.205 51.5234 65.8533 53.1651C64.5048 59.3602 59.7754 64.1873 53.5802 65.7117C51.8018 66.1416 48.2059 66.1416 46.4275 65.7117C40.4473 64.246 35.9329 59.8488 34.3499 53.9468C33.8027 51.9339 33.7636 48.3184 34.2326 46.3641C35.5225 41.107 39.2552 36.788 44.1019 34.9119C45.4113 34.4233 46.0757 34.2474 48.4404 33.837C49.4176 33.6611 52.5445 33.9347 54.0297 34.306Z"
                                    fill="white"
                                />
                                <path
                                    d="M48.7141 40.1689C43.6916 40.9116 40.1934 44.9179 40.1934 49.9405C40.1934 55.4711 44.4733 59.7706 50.004 59.7511C55.5542 59.7315 59.8146 55.4907 59.8146 49.9405C59.8146 47.3412 58.896 44.9765 57.1763 43.1199C55.2024 41.0093 51.5869 39.739 48.7141 40.1689Z"
                                    fill="white"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://dzen.ru/hobjob"
                            className="footer-socials__link"
                        >
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M59.6429 59.6429C51.7857 67.75 51.2143 77.8571 50.5357 100C71.1786 100 85.4286 99.9286 92.7857 92.7857C99.9286 85.4286 100 70.5 100 50.5357C77.8571 51.25 67.75 51.7857 59.6429 59.6429ZM0 50.5357C0 70.5 0.0714285 85.4286 7.21429 92.7857C14.5714 99.9286 28.8214 100 49.4643 100C48.75 77.8571 48.2143 67.75 40.3571 59.6429C32.25 51.7857 22.1429 51.2143 0 50.5357ZM49.4643 0C28.8571 0 14.5714 0.0714285 7.21429 7.21429C0.0714285 14.5714 0 29.5 0 49.4643C22.1429 48.75 32.25 48.2143 40.3571 40.3571C48.2143 32.25 48.7857 22.1429 49.4643 0ZM59.6429 40.3571C51.7857 32.25 51.2143 22.1429 50.5357 0C71.1786 0 85.4286 0.0714285 92.7857 7.21429C99.9286 14.5714 100 29.5 100 49.4643C77.8571 48.75 67.75 48.2143 59.6429 40.3571Z"
                                    fill="white"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://vk.com/hobjob"
                            className="footer-socials__link"
                        >
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.02083 7.02083C0 14.0625 0 25.3542 0 48V52C0 74.625 0 85.9583 7.02083 92.9792C14.0625 100 25.3542 100 48 100H52C74.625 100 85.9583 100 92.9792 92.9792C100 85.9375 100 74.6458 100 52V48C100 25.375 100 14.0417 92.9792 7.02083C85.9375 0 74.6458 0 52 0H48C25.375 0 14.0417 0 7.02083 7.02083ZM16.8958 30.5625C17.4167 56.5625 31.1042 72.2292 53.6667 72.2292H54.9792V57.3333C63.2083 58.1667 69.3333 64.3125 71.8333 72.2292H83.6875C82.5182 67.7687 80.4247 63.6037 77.5428 60.004C74.6609 56.4043 71.0549 53.4503 66.9583 51.3333C72.125 48.2292 79.4583 40.7292 81.1875 30.5625H70.3958C68.1458 38.8333 61.4167 46.3333 54.9792 47.0417V30.5625H44.0208V59.4167C37.3542 57.75 28.6667 49.6667 28.3125 30.5625H16.8958Z"
                                    fill="white"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://www.youtube.com/channel/UCFEZn2Om4MucJT60ApT7y6w"
                            className="footer-socials__link"
                        >
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.6388 0.052527C13.7839 0.332094 12.2545 0.735913 10.9521 1.28469C5.37739 3.63513 1.47015 8.6052 0.236803 14.9006L0 16.0914V49.9501V83.8087L0.226936 84.9788C0.562406 86.7079 1.04588 88.1782 1.82535 89.8142C2.61469 91.4709 3.43364 92.7238 4.59792 94.0388C7.20274 96.9898 10.5673 98.9467 14.3858 99.7233L15.4908 99.9511L49.1365 99.9925C73.3989 100.013 83.1276 99.9925 84.0156 99.9097C86.9164 99.6405 89.5607 98.6775 91.9386 97.0519C95.7176 94.4633 98.3125 90.7047 99.4472 86.1799C100.029 83.8191 99.9997 86.1488 99.9997 49.453C99.9997 15.8843 99.9997 15.8118 99.7925 14.766C99.4768 13.1404 98.9539 11.5665 98.2139 10.0341C96.1912 5.87166 92.9746 2.83784 88.8108 1.16044C87.7353 0.735913 85.8607 0.249259 84.7062 0.0939443C83.9366 0.000755309 16.29 -0.040662 15.6388 0.052527ZM58.7073 25.2032C61.3713 25.2653 67.8143 25.5242 68.6727 25.6174C68.949 25.6381 69.5706 25.6899 70.0541 25.7209C73.6851 25.9591 76.3787 26.4768 77.7502 27.1912C79.7334 28.237 81.3614 30.2768 82.0324 32.5548C83.9465 39.0469 83.9465 60.7496 82.0324 67.2418C81.3614 69.5197 79.7334 71.5595 77.7502 72.6053C76.3688 73.3301 73.6259 73.8478 70.0541 74.0756C69.5114 74.1067 68.8898 74.1585 68.6727 74.1792C68.1893 74.2413 61.9633 74.5105 58.954 74.6037C56.0827 74.6865 43.8677 74.6865 40.9964 74.6037C37.9871 74.5105 31.7611 74.2413 31.2777 74.1792C31.0606 74.1585 30.439 74.1067 29.8963 74.0756C26.3246 73.8478 23.5816 73.3301 22.2002 72.6053C20.217 71.5595 18.589 69.5197 17.9181 67.2418C16.0039 60.7496 16.0039 39.0469 17.9181 32.5548C18.589 30.2768 20.2072 28.2474 22.2002 27.2016C23.5619 26.4768 26.2357 25.9694 29.8963 25.7209C30.3897 25.6899 31.031 25.6381 31.327 25.607C32.9057 25.4621 39.0724 25.2446 43.3151 25.1825C45.9792 25.1514 48.1696 25.0997 48.1992 25.0789C48.2485 25.0272 53.4286 25.0997 58.7073 25.2032Z"
                                    fill="white"
                                />
                                <path
                                    d="M43.167 49.8983C43.167 55.5931 43.1867 60.2526 43.2065 60.2526C43.2361 60.2526 46.7684 58.1507 55.5991 52.87C57.4146 51.7828 59.2696 50.6852 59.7136 50.4263C60.1674 50.1675 60.5325 49.9293 60.5325 49.8983C60.5325 49.8672 59.9602 49.5152 59.2696 49.1113C58.5789 48.7075 56.7042 47.5892 55.1058 46.6366C47.5873 42.1325 43.2361 39.5439 43.2065 39.5439C43.1867 39.5439 43.167 44.2034 43.167 49.8983Z"
                                    fill="white"
                                />
                            </svg>
                        </a>

                        <a
                            href="https://t.me/hobjob"
                            className="footer-socials__link"
                        >
                            <svg
                                width="100"
                                height="100"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="7.5"
                                    y="7.5"
                                    width="85"
                                    height="85"
                                    rx="17.5"
                                    stroke="white"
                                    strokeWidth="15"
                                />
                                <path
                                    d="M50 0C22.5 0 0 22.5 0 50C0 77.5 22.5 100 50 100C77.5 100 100 77.5 100 50C100 22.5 77.5 0 50 0ZM74.5 30.5L66 71.5C65.5 74.5 63.5 75 61.5 73.5L48.5 63.5C45.5 66.5 42.5 69 42 70C41 70.5 40.5 71.5 39.5 71.5C38 71.5 38 70.5 37.5 69.5L33 54.5L19.5 50C16.5 49 16.5 47 20 45.5L71 26C73 25.5 75.5 26.5 74.5 30.5ZM62.5 35L34 53L38.5 68L39.5 58L64 36C65.5 34.5 64.5 34.5 62.5 35Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="footer-menu">
                        <a
                            href="https://hobjob.ru"
                            className="footer-menu__link"
                        >
                            Платформа HobJob
                        </a>
                        <a
                            href="https://hobjob.ru/go/login"
                            className="footer-menu__link"
                        >
                            Войти
                        </a>
                    </div>
                    <div className="footer-email">
                        <div className="footer-email-item">
                            <span className="footer-email-item__subtitle">
                                Служба поддержки
                            </span>
                            <a
                                href="mailto:support@hobjob.ru"
                                className="footer-email-item__email"
                            >
                                support@hobjob.ru
                            </a>
                        </div>

                        <div className="footer-email-item">
                            <span className="footer-email-item__subtitle">
                                Для вопросов и предложений
                            </span>
                            <a
                                href="mailto:support@hobjob.ru"
                                className="footer-email-item__email"
                            >
                                hello@hobjob.ru
                            </a>
                        </div>
                    </div>

                    <p className="footer__subtitle">
                        © HobJob {new Date().getFullYear()}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
