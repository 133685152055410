import React from "react";

import {CourseGood} from "../../../../models/Course/ICourseGood";

interface CoursePagePriceRegisterBuyProductBlockProps extends CourseGood {}

const CoursePagePriceRegisterBuyProductBlock: React.FC<
    CoursePagePriceRegisterBuyProductBlockProps
> = ({image}) => {
    return (
        <div className="course-page-price-register-buy-product-block">
            <div className="course-page-price-register-buy-product-block-text">
                <p className="course-page-price-register-buy-product-block-text__subprice">
                    Курс навсегда
                </p>
                <div className="course-page-price-register-buy-product-block-text-price">
                    <h3 className="course-page-price-register-buy-product-block-text-price__title">
                        990Р
                        <span>1980Р</span>
                    </h3>
                    <p className="course-page-price-register-buy-product-block-text-price__sale">
                        -50%
                    </p>
                </div>
                <div className="course-page-price-register-buy-product-block-text-list">
                    <p className="course-page-price-register-buy-product-block-text-list__item">
                        <svg
                            width="17"
                            height="15"
                            viewBox="0 0 17 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 8.55319C1.35344 9.05484 1.99095 9.33443 2.51005 9.62884C3.73887 10.3258 4.87341 11.1718 6.02955 11.9811C6.50626 12.3148 7.24248 12.7081 7.56028 13.2281C7.65966 13.3907 7.69731 13.1197 7.76714 12.974C8.02023 12.446 8.16902 11.8806 8.43203 11.3546C9.10787 10.0029 9.9949 8.77056 10.8522 7.53073C12.1788 5.61236 13.4636 3.6302 14.9391 1.82151C15.113 1.60833 15.4142 1.13333 15.6809 1"
                                stroke="#F22B2B"
                            />
                            <path
                                d="M1 8.50015C2.41662 9.60197 3.83312 10.699 5.29669 11.7389C5.74638 12.0584 6.13423 12.4641 6.60284 12.7525C6.78275 12.8632 6.96431 12.9422 7.14066 13.048C7.30999 13.1496 7.63947 13.2223 7.75532 13.3672C7.78374 13.4027 7.92318 12.8981 7.93853 12.8352C8.02205 12.4936 8.15014 12.1866 8.25768 11.8542C8.53994 10.9817 9.01757 10.1907 9.40426 9.36304C9.69347 8.74402 10.0651 8.18109 10.4149 7.5959C10.8269 6.90671 11.2086 6.22686 11.6797 5.57462C12.1232 4.96053 12.5754 4.36597 13.0745 3.79566C13.5955 3.20019 14.2342 2.74052 14.8298 2.22356C15.1097 1.98062 15.4824 1.59137 15.8286 1.43751C15.9239 1.39516 15.9667 1.3659 16 1.26611"
                                stroke="#F22B2B"
                            />
                        </svg>
                        Доступ к этому курсу и материалам
                    </p>
                    <p className="course-page-price-register-buy-product-block-text-list__item">
                        <svg
                            width="17"
                            height="15"
                            viewBox="0 0 17 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 8.55319C1.35344 9.05484 1.99095 9.33443 2.51005 9.62884C3.73887 10.3258 4.87341 11.1718 6.02955 11.9811C6.50626 12.3148 7.24248 12.7081 7.56028 13.2281C7.65966 13.3907 7.69731 13.1197 7.76714 12.974C8.02023 12.446 8.16902 11.8806 8.43203 11.3546C9.10787 10.0029 9.9949 8.77056 10.8522 7.53073C12.1788 5.61236 13.4636 3.6302 14.9391 1.82151C15.113 1.60833 15.4142 1.13333 15.6809 1"
                                stroke="#F22B2B"
                            />
                            <path
                                d="M1 8.50015C2.41662 9.60197 3.83312 10.699 5.29669 11.7389C5.74638 12.0584 6.13423 12.4641 6.60284 12.7525C6.78275 12.8632 6.96431 12.9422 7.14066 13.048C7.30999 13.1496 7.63947 13.2223 7.75532 13.3672C7.78374 13.4027 7.92318 12.8981 7.93853 12.8352C8.02205 12.4936 8.15014 12.1866 8.25768 11.8542C8.53994 10.9817 9.01757 10.1907 9.40426 9.36304C9.69347 8.74402 10.0651 8.18109 10.4149 7.5959C10.8269 6.90671 11.2086 6.22686 11.6797 5.57462C12.1232 4.96053 12.5754 4.36597 13.0745 3.79566C13.5955 3.20019 14.2342 2.74052 14.8298 2.22356C15.1097 1.98062 15.4824 1.59137 15.8286 1.43751C15.9239 1.39516 15.9667 1.3659 16 1.26611"
                                stroke="#F22B2B"
                            />
                        </svg>
                        Личный кабинет для обучение
                    </p>
                </div>

                <img
                    src={`${process.env.REACT_APP_IMAGE_DOMEN}/${image.size_1024}`}
                    alt=""
                    className="course-page-price-register-buy-product-block-text__image"
                />
            </div>
        </div>
    );
};

export default CoursePagePriceRegisterBuyProductBlock;
