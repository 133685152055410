import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

import {useTypedSelector} from "../../hooks/useTypedSelector";

import {sendLogout} from "../../redux/actions/logout";

import Logo from "../../assets/images/logo.svg";

const Header: React.FC = () => {
    const dispatch = useDispatch();

    const {userInfo, isLoadedUserInfo} = useTypedSelector(({user}) => user);

    const [modalMenuState, setModalMenuState] = React.useState<boolean>(false);
    const [modalMenuAnimationState, setModalMenuAnimationState] =
        React.useState<boolean>(false);
    const [headerUserMenu, setHeaderUserMenu] = React.useState<boolean>(false);
    const [headerUserMenuAnimateClose, setHeaderUserMenuAnimateClose] =
        React.useState<boolean>(false);

    const HeaderModalMenuRef = React.useRef<HTMLDivElement>(null);
    const headerUserMenuRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        document.body.addEventListener("click", handHeaderModalMenu);
        document.body.addEventListener("click", handHeaderUserMenu);
    }, []);

    const openUserMenu = () => {
        setHeaderUserMenu(true);
    };

    const closeUserMenu = () => {
        setHeaderUserMenuAnimateClose(true);

        setTimeout(() => {
            setHeaderUserMenuAnimateClose(false);
            setHeaderUserMenu(false);
        }, 180);
    };

    const handHeaderUserMenu = (e: Event) => {
        if (e.target !== headerUserMenuRef.current) {
            closeUserMenu();
        }
    };

    const clickLogout = () => {
        dispatch(sendLogout());
    };

    const openModalMenu = () => {
        document.body.style.overflow = "hidden";
        setModalMenuState(true);
    };

    const closeModalMenu = () => {
        setModalMenuAnimationState(true);
        document.body.style.overflow = "visible";

        setTimeout(() => {
            setModalMenuState(false);
            setModalMenuAnimationState(false);
        }, 180);
    };

    const handHeaderModalMenu = (e: Event) => {
        if (e.target === HeaderModalMenuRef.current) {
            closeModalMenu();
        }
    };

    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header-wrapper">
                        <div className="header-right-block">
                            <Link to="/" className="header-logo__link">
                                <img
                                    src={Logo}
                                    alt="HobJob"
                                    className="header-logo__img"
                                />
                            </Link>
                        </div>

                        <nav className="header-left-block">
                            <a href="https://hobjob.ru/go/login" className="btn-small-round header-login__link">
                                Войти
                            </a>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
